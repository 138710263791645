import { useEffect } from 'react';
import type { NextPage } from 'next';
import { useRouter } from 'next/router';

import { authServiceInstance } from '@front/services';

const Test: NextPage = () => {
  const route = useRouter();

  useEffect(() => {
    if (authServiceInstance.isAuthenticated()) {
      route.push('/plan');
    } else {
      route.push('/login');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default Test;
